import { Controller } from '@hotwired/stimulus';
import { Loader } from '@googlemaps/js-api-loader';
import { useClickOutside, useDebounce } from 'stimulus-use';

// https://developers.google.com/maps/documentation/javascript/place-autocomplete-data#maps_place_autocomplete_data_session-javascript

const MIN_QUERY_LENGTH = 3;

const removeQueryParams = (url) => {
  const urlObj = new URL(url);
  urlObj.search = '';
  urlObj.hash = '';
  return urlObj.toString();
};

const buildListItem = ({ name, location, placeId }) => {
  const li = document.createElement('li');
  li.classList =
    'p-3 hover:bg-subtle focus:bg-subtle focus:outline-none	rounded-xl';
  li.dataset.action =
    'click->place-search#selectPlace keydown.enter->place-search#selectPlace keydown.down->place-search#nextResult keydown.up->place-search#previousResult';
  li.dataset.placeId = placeId;
  li.tabIndex = '0';

  const line1 = document.createElement('p');
  line1.innerText = name;
  li.appendChild(line1);

  if (location) {
    const line2 = document.createElement('p');
    line2.classList = 'text-xs';
    line2.innerText = location;
    li.appendChild(line2);
  }

  return li;
};

export default class extends Controller {
  static targets = [
    'results',
    'resultsContainer',
    'name',
    'address',
    'website',
    'phone',
  ];
  static values = {
    apiKey: String,
  };
  static debounces = ['updateQuery'];

  connect() {
    useDebounce(this, { wait: 300 });
    this.initPlaces();
    this.getCurrentLocation();
  }

  resultsContainerTargetConnected() {
    useClickOutside(this, { element: this.resultsContainerTarget });
  }

  clickOutside() {
    this.clearResultsList();
  }

  async initPlaces() {
    this.loader = new Loader({
      apiKey: this.apiKeyValue,
      version: 'weekly',
    });

    const { Place, AutocompleteSessionToken, AutocompleteSuggestion } =
      await this.loader.importLibrary('places');

    this.Place = Place;
    this.AutocompleteSuggestion = AutocompleteSuggestion;
    this.AutocompleteSessionToken = AutocompleteSessionToken;

    this.refreshToken();
  }

  async updateQuery(event) {
    const query = event.target.value;
    if (!query || query.length <= MIN_QUERY_LENGTH) {
      this.clearResultsList();
      return;
    }

    const request = {
      input: query,
      language: 'en-US',
      region: 'us',
      sessionToken: this.token,
    };

    if (this.currentLocation) {
      // send google user's current location for more localized results
      request.origin = this.currentLocation;
    }

    const { suggestions } =
      await this.AutocompleteSuggestion.fetchAutocompleteSuggestions(request);

    this.clearResultsList();

    suggestions.forEach((suggestion) => {
      const placePrediction = suggestion.placePrediction;

      this.resultsTarget.appendChild(
        buildListItem({
          name: placePrediction.mainText.toString(),
          location: placePrediction.secondaryText.toString(),
          placeId: placePrediction.placeId.toString(),
        }),
      );
    });

    this.showResultsList();
  }

  async selectPlace(event) {
    const placeId = event.currentTarget.dataset.placeId;
    const place = new this.Place({
      id: placeId,
      sessionToken: this.token,
    });

    // https://developers.google.com/maps/documentation/javascript/place-class-data-fields
    await place.fetchFields({
      fields: [
        'displayName',
        'formattedAddress',
        'websiteURI',
        'nationalPhoneNumber',
        'location',
      ],
    });

    this.nameTarget.value = place.displayName;
    this.addressTarget.value = place.formattedAddress;
    this.websiteTarget.value = removeQueryParams(place.websiteURI);
    this.phoneTarget.value = place.nationalPhoneNumber;
    // google place id = place.id
    // lat/long = place.location

    this.clearResultsList();
    this.refreshToken();
  }

  showResultsList() {
    if (this.hasResultsContainerTarget) {
      this.resultsContainerTarget.classList.remove('hidden');
      this.activeSelection = undefined;
    }
  }

  clearResultsList() {
    if (this.hasResultsContainerTarget) {
      this.resultsContainerTarget.classList.add('hidden');
    }

    if (this.hasResultsTarget) {
      this.resultsTarget.replaceChildren();
    }
  }

  refreshToken() {
    // tokens are a cost saving measure. they should be refreshed after getting a place id's details
    // https://developers.google.com/maps/documentation/javascript/session-pricing
    this.token = new this.AutocompleteSessionToken();
  }

  getCurrentLocation() {
    navigator.geolocation.getCurrentPosition((position) => {
      const lat = position?.coords?.latitude;
      const lng = position?.coords?.longitude;
      if (lat && lng) {
        this.currentLocation = { lat, lng };
      }
    });
  }

  // arrow navigation
  nextResult(event) {
    event.preventDefault();

    const children = this.resultsTarget.children;

    if (children.length <= 0) {
      return;
    }

    if (
      this.activeSelection === undefined ||
      this.activeSelection === children.length - 1
    ) {
      this.activeSelection = 0;
    } else {
      this.activeSelection += 1;
    }

    children[this.activeSelection].focus();
  }

  previousResult(event) {
    event.preventDefault();

    const children = this.resultsTarget.children;

    if (children.length <= 0) {
      return;
    }

    if (this.activeSelection === undefined || this.activeSelection == 0) {
      this.activeSelection = children.length - 1;
    } else {
      this.activeSelection -= 1;
    }

    children[this.activeSelection].focus();
  }
}
